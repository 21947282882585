import Keycloak from 'keycloak-js';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { RootStoreMobX } from './root.store';
import Cookies from 'js-cookie';
import process from 'process';

const CookiesConf = Cookies.withAttributes({
  expires: 30, //days
  path: '/',
  domain: process.env.REACT_APP_ENV === 'local' ? undefined : '.yoonite.tech',
});

export type SSOContextType = {
  mode: { type: 'children'; parentUrl: string } | { type: 'parent' } | null;
  instance: Keycloak | null;
  authenticated: boolean;
};

const localStorageMapper = {
  refreshToken: 'kcRefreshToken',
  token: 'kcToken',
};

export class SSOStoreMobX {
  root: RootStoreMobX;
  @observable mode: SSOContextType['mode'] = null;
  @observable instance: SSOContextType['instance'] = null;
  @observable authenticated: SSOContextType['authenticated'] = false;
  @observable load: boolean = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  @action public login() {
    if (this.instance) {
      this.instance
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          scope: 'openid email profile roles',
        })
        .then((auth) => {
          if (auth && this.instance?.token && this.instance?.refreshToken) {
            this.connect({
              token: this.instance.token,
              refreshToken: this.instance.refreshToken,
            });
          }
        })
        .catch((error) => {
          console.error(error);

          this.logout();
        });
    }
  }

  @action public restoreSession() {
    return this.instance
      ?.init({
        onLoad: 'check-sso',
        token: CookiesConf.get(localStorageMapper.token!),
        refreshToken: CookiesConf.get(localStorageMapper.refreshToken!),
      })
      .catch((error) => {
        console.error(error);
        this.logout();
      });
  }

  @action public logout() {
    this.disconnect();
    return this.instance?.logout({ redirectUri: window.location.origin });
  }

  @action private connect({
    token,
    refreshToken,
  }: {
    token: string;
    refreshToken: string;
  }) {
    this.authenticated = true;
    CookiesConf.set(localStorageMapper.token, token);
    CookiesConf.set(localStorageMapper.refreshToken, refreshToken);
  }

  @action private disconnect() {
    this.authenticated = false;
    CookiesConf.remove(localStorageMapper.token);
    CookiesConf.remove(localStorageMapper.refreshToken);
  }

  @action public init({
    mode,
    instance,
  }: Pick<SSOContextType, 'mode' | 'instance'>) {
    runInAction(() => {
      this.load = false;
      this.mode = mode;
      this.instance = instance;
      this.load = true;
    });
  }
}
