import { RouterType } from '@gimlite/router';
import MainUse from './main';
import { eventsService } from './pages/events/events.machine';
import { Events } from './pages/events/view/events.page';

import { occupancyService } from './pages/occupancy/occupancy.machine';
import { Occupancy } from './pages/occupancy/view/occupancy.page';
import { vaoService } from './pages/vao/vao.machine';
import { Vao } from './pages/vao/view/vao.page';

export const userPathToPage: any = [
  {
    path: '/',
    Component: MainUse,
    children: [
      // {
      //   path: '/',
      //   Component: HomePage,
      //   machine: homeService,
      // },
      {
        path: '/:id',
        Component: Events,
        machine: eventsService,
      },
      {
        path: '/:id/events',
        Component: Events,
        machine: eventsService,
      },
      {
        path: '/:id/vao',
        Component: Vao,
        machine: vaoService,
      },
      {
        path: '/:id/occupancy',
        Component: Occupancy,
        machine: occupancyService,
      },
    ],
  },
];
