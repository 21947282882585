import process from 'process';

export const apps: Array<{
  name: string;
  label: string;
  description: string;
  icon: string;
  url: { open: string };
}> = [
  {
    name: 'prestoscan',
    label: 'PrestoScan',
    description: 'A service to help with verbalization',
    icon: 'https://presto-scan-dev.yoonite.tech/favicon.svg',
    url: { open: process.env.REACT_APP_HOST_FRONT_PRESTO_SCAN_FAKE },
  },
  {
    name: '2',
    label: 'APP 2',
    description: 'Reference site about Lorem Ipsum, giving information',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
  {
    name: '3',
    label: 'APP 3',
    description: 'Reference site about Lorem Ipsum, giving information',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
  {
    name: '4',
    label: 'APP 4',
    description: 'Reference site about Lorem Ipsum, giving information',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
  {
    name: '5',
    label: 'APP 5',
    description: 'Reference site about Lorem Ipsum, giving information',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
  {
    name: '6',
    label: 'APP 6',
    description: 'Reference site about Lorem Ipsum, giving information',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/ICloud_logo.svg',
    url: { open: 'https://www.iemgroup.com/' },
  },
];
