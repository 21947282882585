import { ConfigProvider, theme as themeAnt } from 'antd';
import { Locale } from 'antd/es/locale';
import de_DE from 'antd/locale/de_DE';
import en_EN from 'antd/locale/en_GB';
import fr_FR from 'antd/locale/fr_FR';
import it_IT from 'antd/locale/it_IT';
import { gsap } from 'gsap';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { Empty } from '../components/empty/empty.component';
import { getCSS, setCSS } from '../functions/css.function';
import { RootStoreContext, useRootStore } from '../hook/useRootStore.hook';
import { useSockets } from '../hook/useSockets.hook';
import { useTranslation } from '../hook/useTranslation.hook';
import infoIntern from '../info';
import '../sass/base/base.scss';
import '../sass/base/reset.scss';
import { GlobalStoreContextType } from '../store/global.store';
import { RootStoreMobX } from '../store/root.store';
import { ThemeStoreContextType } from '../store/theme.store';
import { Languages } from '../types/lang.type';
import { State2FAService } from '../wizards/state2FA/state2FA.machine';
import { state2FAWizard } from '../wizards/state2FA/state2FA.wizard';
import { Chart, registerables, Colors } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { AuthContextType } from '../store/auth.store';
import { SSOContextType } from '../store/sso.store';

Chart.register(...registerables);
Chart.register(zoomPlugin);

class Static {
  static langs: Record<Languages, Locale> = { fr_FR, de_DE, en_EN, it_IT };
}
export const RootStore: RootStoreMobX = new RootStoreMobX();

gsap.config({
  autoSleep: 60,
  force3D: false,
  nullTargetWarn: true,
});

type ConfigProps = {
  custom?: GlobalStoreContextType['custom'];
  children: React.ReactNode;
  theme: ThemeStoreContextType['provider'];
  info?: GlobalStoreContextType['info'];
  server: GlobalStoreContextType['server'];
  maps?: GlobalStoreContextType['maps'];
  authorization: GlobalStoreContextType['authorization'];
  wizards?: GlobalStoreContextType['wizards'];
  debug?: GlobalStoreContextType['debug'];
  tfa?: GlobalStoreContextType['tfa'];
  updatePassword?: GlobalStoreContextType['updatePassword'];
  isResponsive: GlobalStoreContextType['isResponsive'];
  sso?: Pick<SSOContextType, 'mode' | 'instance'>;
};

const ConfigObserver = observer(
  ({
    custom,
    info,
    theme,
    server,
    children,
    wizards,
    authorization,
    maps,
    debug,
    tfa,
    updatePassword,
    isResponsive,
    sso,
  }: ConfigProps) => {
    const { lang } = useTranslation();

    const { AuthStore, ThemeStore, GlobalStore, SSOStore } = useRootStore();

    const socket = useSockets({
      config: { host: server?.ws },
      token: AuthStore.token,
    });

    //! GLobal
    useEffect(() => {
      GlobalStore.setSocket(socket);
    }, [socket]);

    useEffect(() => {
      if (!GlobalStore.load) {
        GlobalStore.init({
          isResponsive,
          custom,
          authorization,
          server,
          debug,
          maps,
          socket,
          tfa,
          updatePassword,
          wizards: [
            ...(wizards || []),
            {
              id: 'state2FA',
              machine: State2FAService,
              component: state2FAWizard,
            },
          ],
          info: info?.version
            ? {
                ...info,
                version: {
                  watermelon: infoIntern().VERSION_WATERMELON,
                  ...info.version,
                },
              }
            : info,
        });
      }
    }, []);

    useEffect(() => {
      if (!ThemeStore.load) {
        ThemeStore.init(theme);
      }
    }, [ThemeStore.load]);

    useEffect(() => {
      if (!SSOStore.load && !!sso) {
        SSOStore.init({ ...sso });
      }
    }, [SSOStore.load, sso]);

    //! vA DIPARAITRE POUR LAISSER PLACE AU SSO
    useEffect(() => {
      if (!AuthStore.load && !sso) {
        AuthStore.init();
      }
    }, [AuthStore.load, sso]);

    //! Add var CSS for Media Querie X Clamp++
    useEffect(() => {
      const windowSizeToCSS = () => {
        setCSS('--vh', `${window.innerHeight * 0.01}px`);
        setCSS('--vw', `${window.innerWidth * 0.01}px`);
      };

      windowSizeToCSS();
      window.addEventListener('resize', windowSizeToCSS);

      return () => {
        window.removeEventListener('resize', windowSizeToCSS);
      };
    }, []);

    useEffect(() => () => GlobalStore.cacheDispose?.(), []);

    return (
      <ConfigProvider
        locale={lang ? Static.langs?.[lang] : Static.langs.en_EN}
        renderEmpty={() => <Empty />}
        theme={{
          algorithm:
            ThemeStore.theme === 'light'
              ? themeAnt.defaultAlgorithm
              : themeAnt.darkAlgorithm,
          token: {
            colorPrimary: getCSS('--color-primary'),
            colorLink: getCSS('--color-primary'),
            colorInfo: getCSS('--color-primary'),
            colorBgContainer: 'transparent',
            colorBgElevated: getCSS('--color-layout'),
            lineHeight: 1,
            colorBorder: getCSS('--color-border'),
            borderRadius: 6,
          },
          components: {
            Pagination: {
              itemActiveBg: 'transparent',
              itemBg: 'transparent',
              itemInputBg: 'transparent',
              itemLinkBg: 'transparent',
            },
            Segmented: {
              itemActiveBg: getCSS('--color-primary'),
              itemSelectedBg: getCSS('--color-primary'),
              itemHoverBg: getCSS('--color-primary'),
            },
            Steps: {
              iconSize: 40, //! En rem
            },
          },
        }}
      >
        {[
          ThemeStore.load,
          SSOStore.load || AuthStore.load,
          GlobalStore.load,
        ].every(Boolean) && children}
      </ConfigProvider>
    );
  },
);

export const Config = (data: ConfigProps) => (
  <RootStoreContext.Provider value={RootStore ?? new RootStoreMobX()}>
    <ConfigObserver {...data} />
  </RootStoreContext.Provider>
);
