import { Router, type RouterType } from '@gimlite/router';
import {
  navigationService,
  notifService,
  translationService,
  useRootStore,
  wizardService,
} from '@gimlite/watermelon';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { appService } from './app.machine';
import { userPathToPage } from './sitemap';

export const CustomRouter = observer(() => {
  const { SSOStore } = useRootStore();

  const store = useSelector(appService, ({ context }: any) => context);

  const sitemap = useMemo(() => {
    // return AuthStore.token ? userPathToPage : guestPathToPage;
    return userPathToPage;
  }, [SSOStore.authenticated]);

  return (
    <Router
      context="browser"
      sitemap={sitemap as any}
      store={store}
      machine={appService}
      modulesMachines={[
        notifService,
        wizardService,
        navigationService,
        translationService,
      ]}
    />
  );
});
