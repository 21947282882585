import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { requestGQLWithoutSchema } from '../functions/request.function';
import { Languages } from '../types/lang.type';
import { RootStoreMobX } from './root.store';
import Keycloak from 'keycloak-js';

export type MeBase = {
  _id: string;
  accountId: string;
  firstName: string; // ou chaine vide
  lastName: string; // ou chaine vide
  username: string;
  lang: Languages;
  tfaActivated: boolean;
};

export type AuthContextType = {
  me: any;
  token?: string;
  names: { firstName: string; lastName: string } | null;
  load: boolean;
  url: string;
};

export class AuthStoreMobX {
  root: RootStoreMobX;
  @observable me: AuthContextType['me'];
  @observable token: AuthContextType['token'];
  @observable names: AuthContextType['names'] = null;
  @observable url: AuthContextType['url'] = window.location.pathname;
  @observable load: AuthContextType['load'] = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  @action public init() {
    runInAction(() => {
      this.load = false;
      this.initToken();
      this.load = true;
    });
  }

  @action setMe(value: AuthContextType['me']) {
    this.me = value;
  }

  @action login(value: AuthContextType['token']) {
    if (value) {
      this.token = value;
      localStorage.setItem('token', value);
    }
  }

  @action setNames(value: AuthContextType['names']) {
    this.names = value;
  }

  @action logout() {
    this.token = undefined;
    this.me = null;
    localStorage.removeItem('token');
  }

  @action async initMe() {
    try {
      const me = await requestGQLWithoutSchema({
        operationName: 'me',
        operationType: 'QUERY',
      });
      this.me = me;
    } catch (error) {
      // localStorage.removeItem('token');
      // window.location.replace('/');
    }
  }

  @action async initToken() {
    const tryGetStorageToken = localStorage.getItem('token');

    runInAction(() => {
      this.token = tryGetStorageToken || undefined;
      this.load = !!tryGetStorageToken;
    });
  }
}
