import packageJSON from '../package.json';

const config = {
  VERSION_FRONT: packageJSON?.version,
  ENV: process.env.REACT_APP_ENV || 'local',
  BFF_HOST:
    process.env.REACT_APP_HOST_BFF_PRESTO_SCAN || 'http://localhost:3107',
  BFF_WS_HOST: process.env.REACT_APP_HOST_BFF_EVENTS || 'ws://localhost:3333',
  BFF_TOKEN:
    process.env.REACT_APP_BFF_TOKEN ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LXByZXN0by1zY2FuIiwiYXBwVmVyc2lvbiI6InYxIiwiaWF0IjoxNzAxOTUzMTgzfQ.SEGl2NFy573tj5Ma1M2Ddy6ToHxAWZCWGoz4mnL-YC4',
  SSO_URL: process.env.REACT_APP_SSO_URL || 'http://localhost',
  SSO_REALM: process.env.REACT_APP_SSO_REALM || 'IEM',
  SSO_CLIENT_ID: process.env.REACT_APP_SSO_CLIENT_ID || 'yoonity',
  SSO_PARENT_URL:
    process.env.REACT_APP_HOST_FRONT_YOONITY || 'http://localhost:3000',
};

export default config;
