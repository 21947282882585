import { ApolloError } from '@apollo/client';
import { dispatch } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apps } from '../../fake/apps.fake';
import { requestGQL } from '../../functions/request.function';
import { toCapitalizeCase } from '../../functions/string.function';
import {
  digitRule,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
} from '../../functions/validator.function';
import { updatePasswordGql } from '../../gql/updatePassword.gql';
import { useDevice } from '../../hook/useDevice.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { notifService } from '../../machines/notif.machine';
import {
  LangContext,
  translationService,
} from '../../machines/translation.machine';
import { wizardService } from '../../machines/wizard.machine';
import { MeBase } from '../../store/auth.store';
import type { Position } from '../../types/component.type';
import { Avatar } from '../avatar/avatar.component';
import { Button } from '../button/button.component';
import { Col } from '../col/col.component';
import { Drawer, DrawerContext, DrawerType } from '../drawer/drawer.component';
import { Form } from '../form/form.component';
import { Icon, IconType } from '../icon/icon.component';
import { Input } from '../input/input.component';
import { Popover } from '../popover/popover.component';
import { Radio, RadioGroup } from '../radio/radio.component';
import { Select } from '../select/select.component';
import { Space } from '../space/space.component';
import { Write } from '../write/write.component';
import './header.component.scss';

class Static {
  public static flagLangException: Record<string, string> = {
    EN: 'GB',
  };

  public static flagLang = (value: string): string => {
    const labeled = Static.langLabeled(value);
    const flagException = Static.flagLangException[labeled];
    return flagException ? flagException : labeled;
  };

  public static langLabeled = (lang: string): string => lang.split('_')[1];
}

export declare namespace HeaderGroupSectionType {
  type Props = {
    handleEvent: {
      close: () => void;
    };
    className?: string;
    data: {
      key: string;
      disabled?: boolean;
      items: HeaderGroupSectionType.Data.Item[];
    };
  };

  namespace Data {
    type Item = {
      key: string;
      icon?: IconType.Config.Type;
      label: string;
      extra?:
        | { type: 'value'; text: string }
        | { type: 'drawer'; node: DrawerType.Data }
        | { type: 'event'; event: () => void }
        | ({ type: 'depth' } & HeaderGroupSectionType.Props['data']);

      disabled?: boolean;
    };
  }
}

const HeaderGroupSection = ({
  data: { items },
  handleEvent: { close },
}: HeaderGroupSectionType.Props) => {
  const { isDesktop } = useDevice();

  return (
    <div className="headerParamsContain__group">
      {items
        .filter(({ disabled = false }) => !disabled)
        .map(({ key, icon, label, extra }) => {
          return (
            <div key={key} className="headerParamsContain__group__option">
              <div className="headerParamsContain__group__option__subject">
                {icon && (
                  <Icon
                    className="headerParamsContain__group__option__subject__icon"
                    config={{
                      type: icon,
                      color: 'label',
                      size: 'xsmall',
                    }}
                  ></Icon>
                )}
                <div className="headerParamsContain__group__option__subject__label">
                  <Write
                    data={{ item: label }}
                    config={{
                      mode: 'label-value',
                      color: 'yang',
                      wrap: false,
                    }}
                  />
                </div>
              </div>
              {extra && (
                <div className="headerParamsContain__group__option__extra">
                  {extra.type === 'drawer' ? (
                    <Drawer
                      data={extra.node}
                      config={{
                        size: isDesktop ? 'xsmall' : 'large',
                        placement: isDesktop ? 'right' : 'bottom',
                      }}
                    >
                      {
                        <Icon
                          config={{
                            color: 'primary',
                            type: 'faChevronRightSolid',
                            size: 'xxsmall',
                          }}
                        />
                      }
                    </Drawer>
                  ) : extra.type === 'event' ? (
                    <div
                      onClick={() => {
                        extra.event();
                        close();
                      }}
                      className="event-trigger"
                    ></div>
                  ) : extra.type === 'value' ? (
                    <Write
                      data={{ item: extra.text }}
                      config={{ mode: 'label' }}
                    ></Write>
                  ) : (
                    <Drawer
                      data={
                        <HeaderGroupSection
                          handleEvent={{
                            close: () => {
                              close();
                            },
                          }}
                          data={{
                            items: extra.items,
                            key: extra.key,
                            disabled: extra.disabled,
                          }}
                        />
                      }
                      config={{
                        size: isDesktop ? 'xsmall' : 'large',
                        placement: isDesktop ? 'right' : 'bottom',
                      }}
                    >
                      {
                        <Icon
                          config={{
                            color: 'primary',
                            type: 'faChevronRightSolid',
                            size: 'xxsmall',
                          }}
                        />
                      }
                    </Drawer>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export declare namespace SSOViewAppType {
  type Props = {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
  };
}

const SSOViewApp = observer(({ children }: SSOViewAppType.Props) => {
  const { SSOStore } = useRootStore();

  const rolesList = SSOStore?.instance?.clientId
    ? SSOStore.instance?.resourceAccess?.[SSOStore.instance.clientId]?.roles ||
      []
    : [];

  const appViewList = useMemo(() => {
    return rolesList
      .filter((value) => value.startsWith('app:view'))
      .map((value) => value.split(':')[2]);
  }, [SSOStore.instance, rolesList]);

  const appUseList = useMemo(() => {
    return rolesList
      .filter((value) => value.startsWith('app:use'))
      .map((value) => value.split(':')[2]);
  }, [SSOStore.instance, rolesList]);

  const appsFormatted = useMemo(() => {
    return apps
      .filter(({ name }) => appViewList.includes(name))
      .map(({ name, ...rest }) => ({
        isActive: appUseList.includes(name),
        name,
        ...rest,
      }));
  }, [SSOStore.instance, appViewList, appUseList, apps]);

  console.log({
    instance: SSOStore.instance,
    rolesList,
    appViewList,
    appUseList,
    appsFormatted,
  });

  return (
    <Popover
      config={{ padding: false, placement: 'topRight' }}
      data={
        <div className="SSOViewApp">
          <div className="SSOViewApp__body">
            {appsFormatted
              .filter(({ url }) => !url.open.startsWith(window.location.origin))
              .map(({ name, label, url, isActive, icon }) => (
                <div
                  onClick={() => {
                    if (isActive) window.location.replace(url.open);
                  }}
                  key={name}
                  className={`SSOViewApp__body__app SSOViewApp__body__app--${
                    isActive ? 'active' : 'inactive'
                  }`}
                >
                  <div className="SSOViewApp__body__app__icon">
                    <img
                      className="SSOViewApp__body__app__icon__item"
                      src={icon}
                      alt=""
                    />
                  </div>
                  <Write
                    data={{ item: label }}
                    config={{ mode: 'namise', wrap: false }}
                  ></Write>
                </div>
              ))}
          </div>
          {/* SSOStore?.mode?.parentUrl */}
          <div
            onClick={() => {
              if (SSOStore?.mode && 'parentUrl' in SSOStore.mode) {
                window.location.replace(SSOStore?.mode!.parentUrl!);
              }
            }}
            className="SSOViewApp__footer"
          >
            <Write
              data={{ item: 'Go to Presto Unity' }}
              config={{ mode: 'namise', color: 'primary-over' }}
            ></Write>
            <Icon
              config={{
                type: 'faArrowRightSolid',
                color: 'primary-over',
                size: 'medium',
              }}
            />
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
});

const SSONamise = observer(() => {
  const { SSOStore } = useRootStore();

  return (
    <Write
      data={{
        item: `${SSOStore.instance?.tokenParsed?.given_name} ${SSOStore.instance?.tokenParsed?.family_name}`,
      }}
      config={{ color: 'primary-over', mode: 'link' }}
    />
  );
});

const SSOAvatar = observer(() => {
  const { SSOStore } = useRootStore();

  return (
    <Avatar
      data={{
        firstName: SSOStore.instance?.tokenParsed?.given_name,
        lastName: SSOStore.instance?.tokenParsed?.family_name,
      }}
      config={{
        backgroundColor: 'primary-over',
        color: 'primary',
      }}
    ></Avatar>
  );
});

export declare namespace HeaderType {
  type Props = {
    className?: string;
    config?: {
      position?: Extract<Position, 'absolute' | 'fixed' | 'static' | 'sticky'>;
      logo?: HeaderType.Config.Logo;
      shortcut?: HeaderType.Config.Shortcut[];
      extra?: HeaderType.Config.Extra;
    };
  };

  namespace Config {
    type Extra = React.ReactNode;
    type Logo = React.ReactNode;
    type Shortcut = {
      icon?: HeaderGroupSectionType.Data.Item['icon'];
      label: HeaderGroupSectionType.Data.Item['label'];
      event: () => void;
    };
  }
}

export const Header = observer(
  ({
    className = '',
    config: { position = 'static', logo, shortcut, extra } = {},
  }: HeaderType.Props) => {
    const { lang: langTranslation, t } = useTranslation();
    const { isDesktop } = useDevice();
    const navigate = useNavigate();
    const [passwordValidFormat, setPasswordValidForm] = useState(false);
    const [passwordValidSame, setPasswordValidSame] = useState(false);
    const [passwordValues, setPasswordValues] = useState<Object>({});
    const { ThemeStore, AuthStore, GlobalStore, SSOStore } = useRootStore();

    const langItems = useSelector(
      translationService,
      ({ context }: { context: LangContext }) => context.items,
    );

    const langSelected = useSelector(
      translationService,
      ({ context }: { context: LangContext }) => context.lang,
    );

    const me = AuthStore.me as MeBase | null;

    const groups = useMemo((): Array<HeaderGroupSectionType.Props['data']> => {
      return [
        {
          key: 'first',
          disabled: !(shortcut && shortcut.length > 0) || !AuthStore.me,
          items: [
            {
              key: 'shortcut',
              label: t('watermelon-shortcut'),
              icon: 'faArrowsUpDownLeftRightSolid',
              extra: {
                type: 'depth',
                key: 'shortcut',
                items: shortcut
                  ? shortcut.map(({ event, label, icon }, index) => ({
                      key: `form-${index}`,
                      label,
                      icon,
                      extra: {
                        type: 'event',
                        event: () => {
                          event();
                        },
                      },
                    }))
                  : [],
              },
            },
          ],
        },
        {
          key: 'params',
          items: [
            {
              key: '2fa',
              label: me?.tfaActivated
                ? t('watermelon-deactivate-2FA')
                : t('watermelon-activate-2FA'),
              icon: 'partnerGoogleAuthentificator',
              disabled: !AuthStore.me || !GlobalStore.tfa,
              extra: {
                type: 'event',
                event: () => {
                  wizardService.send('SHOW_WIZARD', {
                    id: 'state2FA',
                    store: {
                      tfaActivated: !!me?.tfaActivated,
                      email: me?.username,
                    },
                  });
                },
              },
            },
            {
              key: 'password',
              label: t('watermelon-update-password'),
              icon: 'faLockSolid',
              disabled: !AuthStore.me || !GlobalStore.updatePassword,
              extra: {
                type: 'drawer',
                node: (
                  <Form
                    data={{
                      value: passwordValues,
                    }}
                    handleEvent={{
                      submit: async ({ currentPassword, newPassword }: any) => {
                        try {
                          const { success } = await requestGQL({
                            gql: updatePasswordGql,
                            params: { input: { currentPassword, newPassword } },
                          });
                          if (success) {
                            notifService.send('NOTIF', {
                              mode: 'success',
                              content: t(
                                'watermelon-thePasswordHasBeenChanged',
                              ),
                            });
                            setPasswordValues(() => {});
                          } else {
                            notifService.send('NOTIF', {
                              mode: 'error',
                              content: t(
                                'watermelon-passwordCouldNotBeChanged',
                              ),
                            });
                          }
                        } catch (error: unknown) {
                          if (
                            error instanceof ApolloError &&
                            error.message === 'MACC_1'
                          ) {
                            notifService.send('NOTIF', {
                              mode: 'error',
                              content: t(
                                'watermelon-theCurrentPasswordIsIncorrect',
                              ),
                            });
                          } else {
                            notifService.send('NOTIF', {
                              mode: 'error',
                              content: t('watermelon-SERVER_PROBLEM'),
                            });
                          }
                        }
                      },
                      validate: (isValid) => {
                        setPasswordValidForm(() => isValid);
                      },
                      change: (values) => {
                        setPasswordValues(() => values);
                        setPasswordValidSame(
                          () =>
                            values.newPassword === values.confirmNewPassword,
                        );
                      },
                    }}
                    config={{
                      validateOnChange: true,
                      element: { width: 'full', way: 'vertical' },
                    }}
                  >
                    <Form.Item
                      config={{
                        name: 'currentPassword',
                        label: t('watermelon-currentPassword'),
                        rules: [{ required: true }],
                      }}
                    >
                      <Input config={{ type: { name: 'password' } }}></Input>
                    </Form.Item>
                    <Space config={{ way: 'vertical', count: 2 }} />
                    <Form.Item
                      config={{
                        name: 'newPassword',
                        label: t('watermelon-newPassword'),
                        rules: [
                          { required: true },
                          {
                            validator: minLengthPasswordRule,
                          },
                          {
                            validator: uppercaseLetterRule,
                          },
                          {
                            validator: lowercaseLetterRule,
                          },
                          {
                            validator: digitRule,
                          },
                          {
                            validator: specialCharRule,
                          },
                        ],
                      }}
                    >
                      <Input config={{ type: { name: 'password' } }}></Input>
                    </Form.Item>
                    <Space config={{ way: 'vertical', count: 2 }} />
                    <Form.Item
                      config={{
                        name: 'confirmNewPassword',
                        label: t('watermelon-confirmNewPassword'),
                        rules: [
                          { required: true },
                          {
                            validator: minLengthPasswordRule,
                          },
                          {
                            validator: uppercaseLetterRule,
                          },
                          {
                            validator: lowercaseLetterRule,
                          },
                          {
                            validator: digitRule,
                          },
                          {
                            validator: specialCharRule,
                          },
                        ],
                      }}
                    >
                      <Input config={{ type: { name: 'password' } }}></Input>
                    </Form.Item>
                    <Space config={{ way: 'vertical', count: 2 }} />
                    <Button
                      config={{
                        size: 'small',
                        text: t('watermelon-submit'),
                        type: {
                          value: 'submit',
                        },
                        disabled: !(passwordValidFormat && passwordValidSame),
                      }}
                    ></Button>
                    <Space config={{ way: 'vertical', count: 0.5 }} />

                    {passwordValidFormat && !passwordValidSame && (
                      <Col
                        config={{
                          width: 'full',
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <Write
                          data={{ item: t('watermelon-passwordsAreDifferent') }}
                          config={{ color: 'error', mode: 'key-small-light' }}
                        />
                      </Col>
                    )}
                  </Form>
                ),
              },
            },
            {
              key: 'lang',
              label: t('watermelon-update-lang'),
              icon: 'faEarthEuropeSolid',
              extra: {
                type: 'drawer',
                node: (
                  <Form
                    data={{
                      value: {
                        lang: langSelected,
                      },
                    }}
                    handleEvent={{
                      change: (value: any) => {
                        dispatch('SELECTED_LANG', {
                          lang: value.lang as string,
                        });
                        dispatch('UPDATE_LANG', {
                          lang: value.lang as string,
                        });
                      },
                    }}
                    config={{ element: { width: 'full', way: 'vertical' } }}
                  >
                    <Form.Item
                      data={{ defaultValue: langSelected }}
                      config={{ name: 'lang' }}
                    >
                      <Select
                        data={{
                          items: langItems
                            ? langItems.map(
                                (
                                  lang: string,
                                ): {
                                  label: React.ReactNode;
                                  value: string;
                                } => ({
                                  label: (
                                    <div className="select-lang-item">
                                      <img
                                        src={`https://flagsapi.com/${Static.flagLang(
                                          lang,
                                        )}/shiny/32.png`}
                                      ></img>
                                      <span>{Static.langLabeled(lang)}</span>
                                    </div>
                                  ),
                                  value: lang,
                                }),
                              )
                            : [],
                        }}
                      ></Select>
                    </Form.Item>
                  </Form>
                ),
              },
            },
            {
              key: 'theme',
              label: t('watermelon-update-theme'),
              icon: 'faPaintRollerSolid',
              extra: {
                type: 'drawer',
                node: (
                  <Form
                    data={{
                      value: {
                        theme: ThemeStore.theme,
                      },
                    }}
                    handleEvent={{
                      change: (value: any) => {
                        ThemeStore.setTheme(value.theme);
                      },
                    }}
                    config={{ element: { width: 'full', way: 'vertical' } }}
                  >
                    <Form.Item config={{ name: 'theme' }}>
                      <RadioGroup>
                        <Radio
                          data={{
                            value: 'light',
                            label: t('watermelon-light'),
                          }}
                        ></Radio>
                        <Radio
                          data={{ value: 'dark', label: t('watermelon-dark') }}
                        ></Radio>
                      </RadioGroup>
                    </Form.Item>
                  </Form>
                ),
              },
            },
          ],
        },
        {
          key: 'version',
          disabled: GlobalStore?.info?.env === 'production',
          items: [
            {
              key: 'environnement',
              label: t('watermelon-environment'),
              icon: 'faSeedlingSolid',
              extra: {
                type: 'value',
                text: GlobalStore?.info?.env || t('watermelon-not-defined'),
              },
            },
            {
              key: 'watermelon',
              label: t('watermelon'),
              icon: 'faCodeForkSolid',
              extra: {
                type: 'value',
                text:
                  GlobalStore?.info?.version?.watermelon ||
                  t('watermelon-not-defined'),
              },
            },
            {
              key: 'front',
              label: t('watermelon-application'),
              icon: 'faCodeMergeSolid',
              extra: {
                type: 'value',
                text:
                  GlobalStore?.info?.version?.front ||
                  t('watermelon-not-defined'),
              },
            },
          ],
        },
        {
          key: 'link',
          disabled: !AuthStore.me,
          items: [
            // {
            //   key: 'help',
            //   label: t('watermelon-help'),
            //   icon: 'faInfoCircleSolid',
            //   extra: {
            //     type: 'event',
            //     event: () => console.log('help'),
            //   },
            // },
            {
              key: 'logout',
              label: t('watermelon-logout'),
              icon: 'faArrowRightFromBracketSolid',
              extra: {
                type: 'event',
                event: () => {
                  AuthStore.logout();
                  navigate('/');
                },
              },
            },
          ],
        },
      ];
    }, [
      langTranslation,
      ThemeStore.theme,
      langSelected,
      AuthStore.me,
      GlobalStore.tfa,
      passwordValidFormat,
      passwordValidSame,
      passwordValues,
    ]);

    return (
      <header
        id="header"
        className={`
          header 
          header--position--${position}
          ${className}
        `}
      >
        <div className="header__logo">{logo}</div>
        <div className="header__extra">{extra}</div>
        {isDesktop &&
          (!SSOStore.instance || !SSOStore.instance?.tokenParsed ? (
            <div className="header__params">
              <Drawer
                data={
                  <DrawerContext.Consumer>
                    {(drawer) => {
                      return (
                        <div className="headerParamsContain">
                          {me && (
                            <div className="headerParamsContain__identity">
                              <Avatar
                                data={{
                                  firstName: me.firstName,
                                  lastName: me.lastName,
                                }}
                                config={{
                                  color: 'primary-over',
                                  backgroundColor: 'primary',
                                }}
                              ></Avatar>
                              <div className="headerParamsContain__identity__names">
                                {!!me.firstName && !!me.lastName && (
                                  <Write
                                    data={{
                                      item:
                                        me.firstName && me.lastName
                                          ? `${toCapitalizeCase(
                                              me.firstName,
                                            )} ${toCapitalizeCase(me.lastName)}`
                                          : me.firstName && !me.lastName
                                          ? `${toCapitalizeCase(me.firstName)}`
                                          : !me.firstName && me.lastName
                                          ? `${toCapitalizeCase(me.lastName)}`
                                          : t('watermelon-not-defined-names'),
                                    }}
                                    config={{ mode: 'namise' }}
                                  />
                                )}
                                <Write
                                  data={{
                                    item:
                                      me?.username ||
                                      t('watermelon-not-defined-email'),
                                  }}
                                  config={{ mode: 'namise-description' }}
                                />
                              </div>
                            </div>
                          )}
                          {groups
                            .filter(({ disabled = false }) => !disabled)
                            .map((data, index) => {
                              return (
                                <HeaderGroupSection
                                  handleEvent={{
                                    close: () => {
                                      drawer?.close();
                                    },
                                  }}
                                  key={index}
                                  data={data}
                                />
                              );
                            })}
                        </div>
                      );
                    }}
                  </DrawerContext.Consumer>
                }
                config={{
                  size: isDesktop ? 'xsmall' : 'large',
                  placement: isDesktop ? 'right' : 'bottom',
                }}
              >
                {
                  <div className="header__params__account">
                    <Write
                      className="header__params__account__text"
                      data={{
                        item: me
                          ? t('watermelon-my-space')
                          : t('watermelon-params'),
                      }}
                      config={{ mode: 'link', color: 'primary-over' }}
                    ></Write>
                    {me ? (
                      <Avatar
                        className="header__params__account__avatar"
                        data={{
                          firstName: me.firstName,
                          lastName: me.lastName,
                        }}
                        config={{
                          color: 'primary',
                          backgroundColor: 'primary-over',
                        }}
                      ></Avatar>
                    ) : (
                      <Icon
                        config={{
                          type: 'faGearSolid',
                          color: 'primary-over',
                        }}
                      ></Icon>
                    )}
                  </div>
                }
              </Drawer>
            </div>
          ) : SSOStore?.mode?.type === 'parent' ? (
            <div
              onClick={() => SSOStore.instance?.accountManagement()}
              className="header__sso"
            >
              <SSONamise />
              <SSOAvatar />
            </div>
          ) : (
            <SSOViewApp>
              <div className="header__sso">
                <SSONamise />
                {
                  <Icon
                    config={{
                      type: 'faCubesStackedSolid',
                      color: 'primary-over',
                      size: 'large',
                    }}
                  />
                }
              </div>
            </SSOViewApp>
          ))}
      </header>
    );
  },
);
