import { makeAutoObservable, runInAction } from 'mobx';
import { LRUCache } from '../config/utils/cache';
import { RootStoreMobX } from './root.store';

export type GlobalStoreContextWizardType = {
  id: string;
  machine: any;
  component: any;
};

export type GlobalStoreContextType = {
  custom: any;
  server?: {
    bff: string;
    ws?: string;
  };
  authorization?: string;
  wizards?: Array<GlobalStoreContextWizardType>;
  debug?: boolean;
  maps?: string;
  cache?: any;
  socket?: any;
  info?: {
    env?: string;
    version?: Record<string, string>;
  };
  tfa?: {
    label: string;
  };
  updatePassword?: boolean;
  isResponsive: boolean;
};

export class GlobalStoreMobX {
  root: RootStoreMobX;
  custom: GlobalStoreContextType['custom'];
  server: GlobalStoreContextType['server'];
  authorization: GlobalStoreContextType['authorization'];
  wizards: GlobalStoreContextType['wizards'];
  debug: GlobalStoreContextType['debug'];
  maps: GlobalStoreContextType['maps'];
  cache: GlobalStoreContextType['cache'];
  socket: GlobalStoreContextType['socket'];
  info: GlobalStoreContextType['info'];
  tfa: GlobalStoreContextType['tfa'];
  updatePassword: GlobalStoreContextType['updatePassword'];
  isResponsive: GlobalStoreContextType['isResponsive'] = false;
  load: boolean = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  init({
    custom,
    authorization,
    debug,
    maps,
    server,
    socket,
    wizards,
    info,
    tfa,
    updatePassword,
    isResponsive,
  }: Pick<
    GlobalStoreContextType,
    | 'custom'
    | 'authorization'
    | 'debug'
    | 'maps'
    | 'server'
    | 'socket'
    | 'wizards'
    | 'info'
    | 'tfa'
    | 'updatePassword'
    | 'isResponsive'
  >) {
    runInAction(() => {
      this.load = false;
      this.custom = custom;
      this.authorization = authorization;
      this.debug = !!debug;
      this.maps = maps;
      this.server = server;
      this.socket = socket;
      this.wizards = wizards;
      this.info = info;
      this.tfa = tfa;
      this.updatePassword = !!updatePassword;
      this.isResponsive = isResponsive;
      this.load = true;
    });
  }

  setSocket = (socket: GlobalStoreContextType['socket']) => {
    this.socket = socket;
    this.cache = new LRUCache(100, socket);
  };

  cacheDispose = () => {
    this.cache?.dispose();
  };
}
